<template>
<div class="main-raised-page-wrapper">
  <div class="section">
  <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h2 class="title text-danger">NZDRT History</h2>
          <h4>New Zealand is special for its unique beautiful landscape, flora, fauna, tangata whenua, and multicultural mix, so despite having much in common with many countries and people through the world, we need NZ based research and for the largest organ in the body, the skin.
          </h4>
        </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
                  <p><img :src="screenshot1"
                  alt="Raised Image"
                  class="img-raised rounded"></p>
              </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
                  <p><img :src="screenshot2"
                  alt="Raised Image"
                  class="img-raised rounded"></p>
              </div>
            <div class="md-layout-item md-size-100 md-card">
                <md-card-content>
          <div class="spacer"></div>
            <h5>Medical and scientific research is costly to cover specialised equipment, staff, ethical, legal, and administrative requirements and is poorly funded in NZ. Although large drug companies may support some clinical research, it is restricted to the needs of the company, and typically focusses on more common conditions because the expected financial return for them will be greater. Yet, ongoing independent research into wide ranging skin issues, is essential for optimal human wellbeing in our changing environments.</h5>
            <h5>The lack of finance earmarked for NZ dermatology research inspired founder, Dr. Louise Reiche, to initiate the New Zealand Dermatology Research Trust (NZDRT). Her desire for the long-term benefit of skin health in NZ and to continue expanding the knowledge frontiers of dermatology was enthusiastically philosophically, financially, and legally supported by the <a href="https://www.nzdsi.org" target="_blank">New Zealand Dermatological Society Incorporated (NZDSI)</a> and other founding trustees: Drs Paul Jarrett, Sandra Winhoven, Grant Bellaney and Peter Gould, in 2014.</h5>
            <h5>Logo development and design contributions were generously provided by Danyel Simich. In April 2021, Dr. Monique MacKenzie, in collaboration with Te Ama-Rere Tai Rangihuna, Ngāti Kahungungu and Ngāti Tuwharetoa gifted a Māori whakatauki (proverb) to NZ Dermatology Research Trust (NZDRT) to use as the tagline on their logo, 'He kiri mātai - he kiri ora', with the translation meaning 'Supporting Independent Medical Research'.</h5>
            <h5 class="title text-black">The <a href="https://www.nzdsi.org/" target="_blank">NZDSI (NZ Dermatological Society Inc)</a> continues to oversee and support the NZDRT but much more funding is required to actualise the aims of the NZDRT.</h5><h5><a href="#/donate">Support our research</a></h5>
                </md-card-content>
            </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "History",
  components: {
  },
  bodyClass: "history",
  data() {
    return {
      screenshot1: require("@/assets/img/fern.jpg"),
      screenshot2: require("@/assets/img/hand.jpg"),
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
