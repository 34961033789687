<template>
  <div class="main-raised-page-wrapper">
    <div class="section">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2 class="title text-danger">About NZDRT</h2>
            <h4>NZDRT is a charitable trust, with the purpose to benefit New Zealanders by advancing dermatology medical research in skin health and disease.</h4>
            
          </div>
            
            <div class="md-layout-item md-size-50 md-small-size-100">
                  <p><img :src="screenshot1"
                  alt="Raised Image"
                  class="img-raised rounded"></p>
              </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
                  <p><img :src="screenshot2"
                  alt="Raised Image"
                  class="img-raised rounded"></p>
              </div>

          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
          <div class="spacer"></div>
            <h5 class="title text-black">NZDRT Trustees:</h5>
            <p><b>Current:</b></p>
            <p>
              <ul>
                <li>Dr. Louise Reiche, Chairperson 2014 -, Secretary 2014 - 19, 2023 Assistant Treasurer 2014 - 20, </li>
                <li>Dr. Paul Jarrett, Trustee 2014 -</li>
                <li>Dr. David Hepburn, Trustee 2019 -, Treasurer 2019 - 2021</li>
                <li>Dr. David Scollay 2019 -</li>
                <li>Dr. Steven Lamb, Treasurer 2023 -</li>
                <li>Dr. Aravind Chandran, Trustee 2023 -</li>
              </ul>
            </p>
            <p><b>Previous trustees:</b></p>
            <p>
              <ul>
                <li>Dr Sandra Winhoven, 2014 - 2017</li>
                <li>Dr Peter Gould 2014 - 2018</li>
                <li>Dr Grant Bellaney, Trustee 2014 - 2023</li>
                <li>Dr Scott Barker, Trustee 2019 - 2023, Secretary 2021 - 2023</li>
                <li>Dr. Tien-Ming, Trustee, Treasurer 2021 - 2023</li>
              </ul>
            </p>
            <h5 class="title text-black">Associated links:</h5>
            <p>
              <ul>
                <li><a href="https://www.mrinz.ac.nz/" target="_blank">MRINZ</a></li>
                <li><a href="https://www.malaghan.org.nz/" target="_blank">Malaghan Institute</a></li>
                <li><a href="https://dermnetnz.org/" target="_blank">DermNet</a></li>
                <li><a href="https://www.nzdsi.org/" target="_blank">NZDSI (NZ Dermatological Society Inc)</a></li>
              </ul>
            </p>
              </md-card-content>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "AboutUs",
  components: {
  },
  bodyClass: "about-us",
  data() {
    return {
      screenshot1: require("@/assets/img/lab.jpg"),
      screenshot2: require("@/assets/img/condition.jpg"),
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
