<template>
  <div class="wrapper">
    <div id="footer-areas">
      <!-- Big Footer -->
      <footer class="footer footer-black footer-big">
        <div class="container">
          <div class="content">
            <div class="md-layout">
              <div class="md-layout-item md-size-33 md-small-size-100">
                <p><img src="@/assets/img/NZDRT_LOGO-Taglinewhite.png"/></p>
              </div>
              <div class="md-layout-item md-size-66 md-small-size-100">
                <h6>Help Us</h6>
                <p>NZDRT is a charitable trust, so you can claim 33.33% of your donation. Help us advance dermatology medical research in skin health and disease with your donation.  <a href="#/donate">Find out more here</a></p>
                <h6>Contact NZDRT</h6>
                <p><a href="mailto:info@nzdrt.org.nz">info@nzdrt.org.nz</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <!-- end Big Footer -->
  <footer class="footer footer-black">
    <template>
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="#/terms">Terms and Conditions</a>
            </li>
            <li>
              <a href="#/privacy">Privacy Policy</a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, developed by
          <a href="https://firecrest.co.nz/"  target="_blank">Firecrest Systems</a>
        </div>
      </div>
    </template>
  </footer>
      </div>
      </div>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
</style>
