<template>
  <div class="main-raised-page-wrapper">
      
        <div class="md-layout topbanner" :style='{ backgroundImage: "url(" + image + ")", }'>
          <div class="md-layout-item md-size-100 text-center">
                    <img src="@/assets/img/NZDRT_logo_main.png">
          </div>
        </div>
          
    <div class="section">
    <div class="container">
        <div class="md-layout">
            <div class="md-layout-item md-size-50 md-small-size-100">
                  <h2 class="title text-black">Purpose / Mission</h2>
                  <h4 class="title text-danger">He Kiri Mātai, He Kiri Ora</h4>
                  <h5>translates to a strongly held belief that skin research is essential to achieving good health of the skin and overall wellbeing.
                  </h5>
            </div>
            <div class="md-layout-item md-size-50 md-small-size-100">
              <h2 class="title text-black">What is dermatology?</h2>
              <p><i>“Dermatology involves but is not limited to the diagnosis and study of disorders, diseases, cancers, cosmetic and ageing conditions of the skin, fat, hair, nails and mucous membranes, and the management of these by different therapies, including but not limited to, topical and systemic medications, dermatologic and cosmetic surgery, phototherapy, laser therapy, radiotherapy and photodynamic therapy.”</i></p>
            </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                    <p><img :src="screenshot1"
                    alt="Raised Image"
                    class="img-raised rounded"></p>
                </div>
              <div class="md-layout-item md-size-50 md-small-size-100">
                    <p><img :src="screenshot2"
                    alt="Raised Image"
                    class="img-raised rounded"></p>
                </div>
            <div class="spacer"></div>
            <hr>
            <div class="spacer"></div>
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
              <div class="leadIcon"><md-icon class="text-danger">volunteer_activism</md-icon></div>
                  <h3 class="title"><a href="#/donate">Donate To NZDRT</a></h3>
                  <h5><a href="#/donate">Support our research</a></h5>
            </div>
            
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
              <div class="leadIcon"><md-icon class="text-danger">menu_book</md-icon></div>
                  <h3 class="title"><a href="#/history">NZDRT History</a></h3>
                  <h5><a href="#/history">Find out why NZDRT was formed</a></h5>
            </div>
            
            <div class="md-layout-item md-size-33 md-small-size-100 text-center">
              <div class="leadIcon"><md-icon class="text-danger">biotech</md-icon></div>
                  <h3 class="title"><a href="#/about-us">About The NZDRT</a></h3>
                  <h5><a href="#/about-us">See who makes up the NZDRT</a></h5>
            </div>
  
            <div class="md-layout-item md-size-100 md-card">
                <md-card-content>
                  <h2 class="title text-black">Trust objectives</h2>
                  <h5>Advancement of medical research into dermatology: healthy and unhealthy skin, and treatments of skin related conditions and/or illness, by NZ dermatologists.</h5>
                  <p>The NZDRT aims to achieve these by</p>
                  <p>
                  <ul>
                    <li>Providing grant and scholarship awards to enable dermatologists or dermatology trainees to undertake research projects in areas of dermatology, approved by the trustees.</li>
                    <li>Building financial capital e.g., income, donations, bequeaths, investments to support sustainable award provision.</li>
                    <li>Provision of specialist advice, direction, and guidance to associated professionals and service providers who work and advise in the field of dermatology or who develop and promote various treatments and innovations in respect of the practice of dermatology.</li>
                    <li>Collaborating with organisations, individuals, and entities with the common goals of education, research, and innovation in the field of dermatology </li>
                    <li>Working with medical services businesses that wish to improve the quality of treatments to patients by offering academic and clinical expertise and providing independent research.</li>
                    <li>Honouring academic independence by developing formal policies and arrangements for working with external parties, such as government agencies, commercial medical entities, academic and professional training institutions, and any other entity or body. </li>
                    <li>Awards will be granted at the discretion of the NZDRT board of trustees, for worthy research projects. Priority areas will likely have an Aotearoa NZ bias (e.g., related to needs of Māori, NZ predominant conditions, novel NZ-based therapies), rare conditions and skin issues related to inequity.</li>
                    <li>Research projects supported at any stage by the NZDRT, will acknowledge NZDRT. Projects eventuating financial profits, will return royalties to the NZDRT.</li>
                  </ul></p>
                </md-card-content>
            </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Index",
    components: {
    },
    bodyClass: "home",
    data() {
      return {
        image: require("@/assets/img/homeBck.jpg"),
        screenshot1: require("@/assets/img/fern.jpg"),
        screenshot2: require("@/assets/img/IMG_4646.jpg"),
      };
    },
  };
  </script>
  <style lang="scss" scoped>
  .leadIcon .md-icon{
    font-size: 40px !important;
  }
  </style>
