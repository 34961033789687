<template>
<div class="main-raised-page-wrapper">
  <div class="section">
  <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h2 class="title text-danger">Grants / Scholarships </h2>
          <h4>The NZDRT aims to provide grant and scholarship awards to enable dermatologists or dermatology trainees to undertake research projects in areas of dermatology.</h4>
        </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
            <h4 class="title text-black">Grant application to the New Zealand Dermatology Research Trust (NZDRT)</h4>
            
            <h5 class="title text-black">Purpose:</h5>
          <p>The NZDRT focuses on supporting independent dermatology research undertaken in New Zealand. Grants can be awarded to New Zealand dermatology trainees in training with the Royal Australasian College of Physicians and to others provided the researcher has a supervisor who is a current member of the New Zealand Dermatological Society.</p>
          <p>All types of research expenses will be considered.</p>
          <p>Successful awards will be posted on the NZDRT website details may include project title, investigators names, institution or practice, the value of the award and the reference if the research results in a publication in a peer reviewed journal.</p>
          <h5 class="title text-black">Application process:</h5>
          <p>Applications, including the lead investigators curriculum vitae, should be e mailed to <a href="mailto:info@nzdrt.org.nz">info@nzdrt.org.nz</a> and  they will be considered by The Trustees of the NZDRT.</p>
          <h5><a href="/images/Application-form_ 2024_Final.docx" target="_blank"><i class="fas fa-file-word"></i> Download the application form</a></h5>
              </md-card-content>
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
                <h5 class="title text-black">Awarded Grants</h5>
            <h5>Sabrina Sapsford (Dermatology Trainee) has been awarded $500 for her University of Auckland Masters research titled “What are the clinical characteristics of atopic eczema in Māori and Pacific compared to European and Pakeha New Zealanders?</h5>
              </md-card-content>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Grants",
  components: {
  },
  bodyClass: "grants",
};
</script>
<style lang="scss" scoped>
</style>
