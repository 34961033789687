// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import VueGtag from 'vue-gtag';
import App from "./App.vue";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/main.css';
import router from "./router";
import globalPlugins from "./plugins/globalPlugins";

import MaterialKit from "./plugins/material-kit";
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
  siteKey: '6Ld-gRYpAAAAAGFincPkaCfhwhb6EdQY6dwQuKoC',//6Ld-gRYpAAAAAGFincPkaCfhwhb6EdQY6dwQuKoC for publish, 6LeR7BcpAAAAAGVJlupVn9FLWWS-3dJfnx-UjjOl for dev//
  loaderOptions: {
    useRecaptchaNet: true,
   // autoHideBadge: true//
  }
})
Vue.config.productionTip = false;
Vue.use(globalPlugins);

Vue.use(MaterialKit);
Vue.use(VueGtag, {
  config: {
      id: 'G-VJP3NC6TH9',
      params: {
          send_page_view: false
      }
  }
}, router)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
