<template>
<div>
  <form @submit.prevent="submit" v-if="!success">
    <h5 class="title text-black">Send us a message</h5>
    <md-field>
      <label>Name</label>
      <md-input v-model="data.inputs.name" required type="text" />
    </md-field>
    <md-field>
      <label>Email</label>
      <md-input v-model="data.inputs.email" required type="email" />
    </md-field>
    <md-field>
      <label>Phone</label>
      <md-input v-model="data.inputs.phone" type="text" />
    </md-field>
    <md-field>
      <label>Message</label>
      <md-textarea v-model="data.inputs.message" required ></md-textarea>
    </md-field>

    <div class="button-container justify-content-center">
        <md-button class="md-danger mt-3" type="submit" @click="recaptcha" >
          Send </md-button>
    </div>
  </form>
  <div class="messages">
  <div v-if="success"><h4>Your message has been sent, we will be in touch soon.</h4>
            <md-button class="md-danger"  @click="resetForm">Reset Form</md-button></div>
  <div v-if="sending"><h4>Sending your message now.</h4></div>
  <div v-if="error"><h4>There was an unknown issue sending your message, please wait and try again later, thanks.</h4></div>
</div>
</div>
</template>
<script>
import env from '/src/env.json';
export default {
  name: "Form",

  data() {
    return {
      success: false,
      error: false,
      sending: false,
      data: {
        inputs: {
          name: "",
          email: "",
          phone: "",
          message: ""
        },
      }
    }
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login')
    },

    resetForm() {
      // Reset form data
      this.data = {
        inputs: {
          name: "",
          email: "",
          phone: "",
          message: ""
        },
      };
      this.success = false
    },

    submit() {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": env.kea.xApiKey,
          "PrimaryDomain": env.primaryDomain,
        },
        body: JSON.stringify({
          "HandlerID": "NZDRT_ContactForm",
          "Packet": {
            ...this.data.inputs,
            "AndTo": [
              {
                "Email": this.data.inputs.email
              }
            ]
          }
        })
      };
      
      const self = this;
      self.sending = true;
      fetch(`${env.kea.url}/${env.application}/chirp/public`, requestOptions)
        .then(r => {
          if (r.status != 200)
            throw `Chirp request failed (${r.status})`;
          self.success = true;
          self.sending = false;
        })
        .catch(e => {
          document.write(e);
          self.error = true;
          self.sending = false;
        });
    },
  },
}
</script>

<style scoped>
.messages{
  text-align:center;
  color:red;
}
</style>