import Vue from "vue";
import Router from "vue-router";

//  Main Pages
import Index from "./pages/Index.vue";
import AboutUs from "./pages/AboutUs.vue";
import ContactUs from "./pages/ContactUs.vue";
import Privacy from "./pages/Privacy.vue";
import Terms from "./pages/Terms.vue";
import Grants from "./pages/Grants.vue";
import History from "./pages/History.vue";
import Students from "./pages/Students.vue";
import Donate from "./pages/Donate.vue";
import Publications from "./pages/Publications.vue";
import EthicalInvesting from "./pages/EthicalInvesting.vue";

//  Main Layout
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      alias: ["/index", "/home"],
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/about-us",
      name: "about-us",
      components: { default: AboutUs, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/contact-us",
      name: "contact-us",
      components: { default: ContactUs, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/terms",
      name: "terms",
      components: { default: Terms, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/privacy",
      name: "privacy",
      components: { default: Privacy, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/grants",
      name: "grants",
      components: { default: Grants, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/history",
      name: "history",
      components: { default: History, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/students",
      name: "students",
      components: { default: Students, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/donate",
      name: "donate",
      components: { default: Donate, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/publications",
      name: "publications",
      components: { default: Publications, header: MainNavbar, footer: MainFooter },
    },
    {
      path: "/ethical-investing",
      name: "ethical-investing",
      components: { default: EthicalInvesting, header: MainNavbar, footer: MainFooter },
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
