<template>
  <div class="main-raised-page-wrapper">
    <div class="section">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2 class="title text-danger">Support NZDRT</h2>
            <h4>Aotearoa is special for its unique beautiful landscape, flora, fauna, tangata whenua and multicultural mix, so we need New Zealand based research for the largest organ of the body, the skin (kiri). Your donation will be used to fund New Zealand based research to benefit the skin health of all New Zealanders.</h4>
            <md-button class="md-danger" href="#/ethical-investing">See NZDRT Ethical investing Policy</md-button>
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
          <div class="spacer"></div>
            <h3 class="title text-black">Make a Donation</h3>
            </div>
          <div class="md-layout-item md-size-50 md-small-size-100"><br>
                  <p><img :src="screenshot1"
                  alt="Raised Image"
                  class="img-raised rounded"></p><br>
            <h5>NZDRT is a charitable trust, so you can claim 33.33% of your donation back as a tax credit from the IRD. The NZDRT would be grateful to receive your tax credit refund and future donations.</h5>
            <h5>Because the NZDRT is a charitable trust, for every donation over $5.00 you will receive a receipt. You can then claim 33.33% of each donation back as a tax credit from the IRD at the end of the tax year.
            </h5>
            <h5 class="title text-black">The NZDRT bank account to receive deposits is:</h5>
            <h5>03 0435 0008802 00</h5>
            <h5>Please use 'donation' and your name as reference.</h5>
            <h5>Please fill in the form for more information and to get a receipt for your donation.</h5>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
          <support-form />
          </div>
          </div>
              </md-card-content>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>

import SupportForm from "../components/SupportForm";
export default {
  name: "Support",
  components: {
    SupportForm,
  },
  bodyClass: "support",
  data() {
    return {
      screenshot1: require("@/assets/img/lab2.jpg"),
    }}
};
</script>
<style lang="scss" scoped>
</style>
