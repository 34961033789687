<template>
<div class="main-raised-page-wrapper">
  <div class="section">
  <div class="container">
      <div class="md-layout">
        <div class="md-layout-item md-size-100">
          <h2 class="title text-danger">Medical Student Assistants</h2>
          <h4>The Dermatology Interest Group for medical students has compiled a list of students here that can be contacted to assist dermatologists with their research.</h4>
          <h5><a href="https://www.nzdsi.org/Students/dig.aspx" target="_blank">More about the DIG Group</a></h5>
        </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
            <h5 class="title text-black">Neakiry Kivi</h5>
            <p><b>Contact:</b> <a href="mailto:neakiryk@outlook.com">neakiryk@outlook.com</a></p>
              <p><b>Location:</b> Wellington</p>
              <p><b>Interests:</b> Medical dermatology; Integrated healthcare delivery; Medical education; Telemedicine; Health promotion; Lifestyle and preventative medicine</p>
              <p><b>Relevant experience:</b> Assistant research fellow at the Medical Research Institute of New Zealand. Experience with carrying out remote patient surveys/data collection, audits, clinical research recruitment, systematic literature reviews, and database/collection building. Keen to build experience in a variety of dermatological research areas and methodologies.
            </p>
              </md-card-content>
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
            <h5 class="title text-black">Loimata Fa'alogo-Lilo</h5>
            <p><b>Contact:</b> <a href="mailto:loimatafaalogolilo@gmail.com">loimatafaalogolilo@gmail.com</a></p>
              <p><b>Location:</b> Auckland</p>
              <p><b>Interests:</b> I have a wide interest in dermatological research including skin of colour.</p>
              <p><b>Relevant experience:</b> I have conducted research on the representation of skin of colour in the Australasian Journal of Dermatology which was presented at NZDSI 2023. I have also completed an elective in dermatology.
            </p>
              </md-card-content>
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
            <h5 class="title text-black">Jacob Junior Kang (JJ Kang)</h5>
            <p><b>Contact:</b> <a href="mailto:jkan344@aucklanduni.ac.nz">jkan344@aucklanduni.ac.nz</a></p>
              <p><b>Location:</b> Auckland</p>
              <p><b>Interests:</b> I have interests in researching on atopic dermatitis, acne, eczema and psoriasis but I am open to any topic. I am very keen on working with data analytics and want to contribute to the developing community of integrating pattern recognition as a tool for diagnosis and management with machine learning across the industry. I believe it has potential to conduct multivariate analyses on biopsies and become a powerful tool for future dermatology.</p>
              <p><b>Relevant experience:</b> I have previously published an article for Dermnet after doing literature review on the topic Talimogene Laherparepvec and planning on writing more articles in the future. I have some experience in handling and analysing data throughout my business degree and hoping to apply it through diverse statistical analyses for data results.
            </p>
              </md-card-content>
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
            <h5 class="title text-black">Louise Kim</h5>
            <p><b>Contact:</b> <a href="mailto:kimlo515@student.otago.ac.nz">kimlo515@student.otago.ac.nz</a></p>
              <p><b>Location:</b> Christchurch</p>
              <p><b>Interests:</b> I have a wide interest in dermatological research including, but not limited to, Atopic Dermatitis and Cosmetic Dermatology.</p>
              <p><b>Relevant experience:</b> I am passionate and determined to learn and gain experience beyond the research skills that are taught within medical school!
            </p>
              </md-card-content>
          </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Students",
  components: {
  },
  bodyClass: "students",
};
</script>
<style lang="scss" scoped>
</style>
