<template>
  <div class="main-raised-page-wrapper">
    <div class="section">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2 class="title text-danger">Ethical Investment Policy</h2>
            <h5 class="title"><a target="_blank" href="/images/2023-NZDRT-ethical-investment-policy-13.11.23.pdf"><md-icon>description</md-icon> Download Policy PDF</a></h5>
            <h4>Known by a variety of different terms ethical, sustainable, or responsible investing is a broad-based approach to investing which factors in people, society, and the environment, along with financial performance when making and managing investments.</h4>
            
          </div>
          <div class="md-layout-item md-size-100 md-card">
              <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
          <div class="spacer"></div>
            <h3 class="title text-black">Background</h3>
            </div>
          <div class="md-layout-item md-size-100"><br>
            <h5>Considerations go beyond environmental issues (e.g., climate change), to social issues (e.g. minimum wage levels, and how supply chains are managed, how companies are governed (e.g. how many women are in top levels of management or transparency into how much the CEO is paid. Interestingly, companies with strong corporate social responsibility and environmental performance often perform better financially too!</h5>
            <h5><a href="https://www.responsiblereturns.com.au/" target="_blank">Responsible Returns</a> includes a broad range of products that have been certified as ‘true-to-label’ by the Responsible Investment Association Australasia (RIAA)for their responsible investment claims.  Many NZ companies use this reference. </h5>
            <h5>According to the RIAA, Responsible Investment is a process that takes into account <b> “environmental, social, governance (ESG) and ethical issues into the investment process of research, analysis, selection and monitoring of investments”.</b> </h5>
            <h5>The UN Principles of Responsible Investment (UNPRI) for incorporating environmental, social and governance (ESG) issues into investment practice, involves six principles expected of compliant companies:</h5>
            <h5>
              <ol>
                <li><b>Incorporating ESG issues</b> into investment analysis and decision-making processes,</li>
                <li><b>Becoming active owners</b> and incorporating ESG issues into their ownership policies and practices.</li>
                <li><b>Seeking appropriate disclosure</b> on ESG issues by the entities in which they invest.</li>
                <li><b>Promoting acceptance and implementation</b> of the Principles within the investment industry.</li>
                <li><b>Working together</b> to enhance the effectiveness in implementing the Principles.</li>
                <li><b>Reporting on their activities</b> and progress towards implementing the Principles.</li>
              </ol>
            </h5>
            <h3 class="title text-black">The New Zealand Dermatology Research Trust</h3>
            <h5>Requires companies NZDRT invests with, to: </h5>
            <h5><ol>
              <li>advise how they approach responsible investment, and </li>
              <li>b.	advise whether they have an ethical investment option and, preferably, </li>
              <li>c.	by which standard (RIAA, UNPRI) those investments adhere to.</li>
            </ol>
          </h5>
            <h5>NZDRT preferentially chooses to invest in companies that are recognised by RIAA or UNPRI. </h5>
            <h5>This policy is reviewed / updated annually. </h5>
            <h5>NZDRT has advised our main investment provider, Forsyth Barr, of these principles. (Chairperson, Dr Reiche, October 2023) </h5>
            <p>
              <table>
                <tr><td><img class="sign" src="@/assets/img/chairSigniture.png"></td><td>Chairperson NZDRT</td></tr>
              </table> </p>
            <p><i>Approved NZDRT Trustees 13 November 2023 </i></p>
          </div>
          </div>
              </md-card-content>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "EthicalInvesting",
  components: {
  },
  bodyClass: "ethical-investing",
};
</script>
<style lang="scss" scoped>
.sign{width:50px !important;
float:left;}
</style>
