<template>
<md-toolbar
    id="toolbar"
    class="mainmenu md-white">
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <h3 class="md-title"><a href="#/"><img :src="'/images/2018_NZDRT_LOGO-NoTag.png'"/></a>
        </h3>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()">
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <md-list-item href="#/home" @click="toggleNavbarMobile()">
                 Home
              </md-list-item>
              <md-list-item href="#/about-us" @click="toggleNavbarMobile()">
                 About
              </md-list-item>
              <md-list-item href="#/donate" @click="toggleNavbarMobile()">
                 Donate
              </md-list-item>
              <md-list-item href="#/history" @click="toggleNavbarMobile()">
                 History
              </md-list-item>
              <md-list-item href="#/students" @click="toggleNavbarMobile()">
                 Student Assistants
              </md-list-item>
              <md-list-item href="#/grants" @click="toggleNavbarMobile()">
                 Grants
              </md-list-item>
              <md-list-item href="#/publications">
                 Publications
              </md-list-item>
              <md-list-item href="#/contact-us" @click="toggleNavbarMobile()">
                 Contact
              </md-list-item>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>


  <script>
  let resizeTimeout;
  function resizeThrottler(actualResizeHandler) {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if (!resizeTimeout) {
      resizeTimeout = setTimeout(() => {
        resizeTimeout = null;
        actualResizeHandler();
  
        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  }
  
  import MobileMenu from "@/layout/MobileMenu";
  export default {
    components: {
      MobileMenu
    },
    data() {
    return {
      toggledClass: false,
    };
  },
    methods: {
      bodyClick() {
        let bodyClick = document.getElementById("bodyClick");
  
        if (bodyClick === null) {
          let body = document.querySelector("body");
          let elem = document.createElement("div");
          elem.setAttribute("id", "bodyClick");
          body.appendChild(elem);
  
          let bodyClick = document.getElementById("bodyClick");
          bodyClick.addEventListener("click", this.toggleNavbarMobile);
        } else {
          bodyClick.remove();
        }
      },
      toggleNavbarMobile() {
        this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
        this.toggledClass = !this.toggledClass;
        this.bodyClick();
      },
    }
  };
  </script>