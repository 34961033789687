<template>
  <div class="main-raised-page-wrapper">
    <div class="section">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2 class="title text-danger">Contact </h2>
          </div>
            <div class="md-layout-item md-size-100 md-card">
                <md-card-content>
        <div class="md-layout">
          <div class="spacer"></div>
          <div class="md-layout-item md-size-50 md-small-size-100">
              <h5 class="title text-black">Email:</h5>
            <h5><a href="mailto:info@nzdrt.org.nz">info@nzdrt.org.nz</a></h5>
              <h5 class="title text-black">Please Note:</h5>
              <p>The NZDRT does not provide an on-line consultation service. If you have any concerns with your skin or its treatment, see a dermatologist or your family doctor for advice. </p>
          </div>
          <div class="md-layout-item md-size-50 md-small-size-100">
            
          <contact-form />
          </div>
        </div>
                </md-card-content>
            </div>
        </div>
      </div>
      </div>
    </div>
  </template>

<script>
import ContactForm from "../components/ContactForm";
export default {
  name: "ContactUs",
  components: {
    ContactForm,

  },
  bodyClass: "contact-us",
};
</script>

<style lang="scss" scoped>
</style>
