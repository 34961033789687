<template>
  <div class="main-raised-page-wrapper">
    <div class="section">
    <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <h2 class="title text-danger">Publications and Reports </h2>
          </div>
            <div class="md-layout-item md-size-100 md-card">
                <md-card-content>
              <h5 class="title text-black">Mānuka oil based ECMT-154 versus vehicle control for the topical treatment of eczema: study protocol for a randomised controlled trial in community pharmacies in Aotearoa New Zealand.</h5>
            <p>Shortt G, Shortt N, Bird G, et al.</p>
            <p>BMC Complement Med Ther. 2024;24(1):61. Published 2024 Jan 29. doi:10.1186/s12906-024-04358-9</p>
            <p><a target="_blank" href="https://bmccomplementmedtherapies.biomedcentral.com/articles/10.1186/s12906-024-04358-9">Full Text Sources - BioMed Central</a></p>
                </md-card-content>
            </div>
        </div>
      </div>
      </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "Publications",
    components: {
    },
    bodyClass: "publications",
  };
  </script>
  <style lang="scss" scoped>
  </style>
